@use '../../../sass/variables';
/* alignment differences */

/* default */

.text-media-variable {
    /* text-left-image-right */
    .text-left-image-right {
        .text-wrapper {
            padding-left: 25px;
            padding-right: 25px;
            margin: auto;
        }

        /* media display logic */
        .image-wrapper {
            img {
                max-width: 100%;
            }
        }
    }

    /* text-right-image-left */
    .text-right-image-left {
        .text-wrapper {
            padding-left: 25px;
            padding-right: 25px;
        }

        /* media display logic */
        .image-wrapper {
            img {
                max-width: 100%;
            }
        }
    }

    /* text-top-image-bottom */
    .text-top-image-bottom {
        .text-wrapper {
            padding-bottom: 50px;
        }

        /* media display logic */
        .image-wrapper {
            img {
                display: block;
                margin-left: auto;
                margin-right: auto;
                max-width: 100%;
            }
        }
    }

    /* text-bottom-image-top */
    .text-bottom-image-top {
        .text-wrapper {
            padding-top: 50px;
        }

        /* media display logic */
        .image-wrapper {
            img {
                margin-left: auto;
                margin-right: auto;
                max-width: 100%;
            }
        }
    }
    summary {
        cursor: pointer;
    }

    .inner-content {
        p {
            margin-bottom: 10px;
        }

        a {
            font-size: inherit;
        }
    }
}

/* media queries */

@media only screen and (max-width: 992px) {
    .text-media-variable {
        div {
            .text-wrapper {
                text-align: center !important;
            }
        }

        .text-left-image-right {
            .text-wrapper {
                padding: 0 15px 50px;
            }

            .image-wrapper {
                padding: 0 15px;
            }
        }

        .text-top-image-bottom {
            .text-wrapper {
                padding: 0 15px 50px;
            }

            .image-wrapper {
                padding: 0 15px;
            }
        }

        .text-right-image-left {
            .text-wrapper {
                padding: 50px 15px 0;
            }

            .image-wrapper {
                padding: 0 15px;
            }
        }

        .text-bottom-image-top {
            .text-wrapper {
                padding: 50px 15px 0;
            }

            .image-wrapper {
                padding: 0 15px;
            }
        }
    }
}

.text-media-variable + .text-media-variable-no-title,
.text-media-variable + .standard-text-no-title,
.text-media-variable + .media-text-columns-no-title,
.text-media-variable + .standard-table-no-title {
    padding-top: 0;
}

.text-media-variable-has-image + .text-media-variable-no-title,
.text-media-variable-has-image + .standard-text-no-title,
.text-media-variable-has-image + .media-text-columns-no-title,
.text-media-variable-has-image + .standard-table-no-title {
    padding-top: 0;
}

.text-media-variable + .media-text-columns-no-title-no-text {
    margin-top: -25px;
}

@media only screen and (max-width: 768px) {
    .text-media-variable {
        div {
            .text-wrapper {
                text-align: center !important;
            }
        }

        .text-left-image-right {
            .text-wrapper {
                padding: 0 15px 15px;
            }

            .image-wrapper {
                padding: 0 15px;
            }
        }

        .text-top-image-bottom {
            .text-wrapper {
                padding: 0 15px 15px;
            }

            .image-wrapper {
                padding: 0 15px;
            }
        }

        .text-right-image-left {
            .text-wrapper {
                padding: 15px 15px 0;
            }

            .image-wrapper {
                padding: 0 15px;
            }
        }

        .text-bottom-image-top {
            .text-wrapper {
                padding: 15px 15px 0;
            }

            .image-wrapper {
                padding: 0 15px;
            }
        }
    }

    .text-media-variable + .text-media-variable-no-title,
    .text-media-variable + .standard-text-no-title,
    .text-media-variable + .media-text-columns-no-title,
    .text-media-variable + .standard-table-no-title,
    .text-media-variable-has-image + .text-media-variable-no-title,
    .text-media-variable-has-image + .standard-text-no-title,
    .text-media-variable-has-image + .media-text-columns-no-title,
    .text-media-variable-has-image + .standard-table-no-title {
        margin-top: 0;
        padding-top: 0;
    }

    .text-media-variable + .media-text-columns-no-title-no-text {
        margin-top: -5px;
    }
}
